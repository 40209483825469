import {
    GET_CENTRES_DATA,
    GET_CENTRES_DATA_SUCCESS,
    GET_CONTEXT_DATA_SUCCESS,
    GET_CANDIDATE_LIST,
    SET_CANDIDATE_IDENTIFIER,
    SET_CURRENT_CENTRE_ID,
    SET_CURRENT_USER_ID,
    TOGGLE_ALERT,
    FETCH_CANDIDATE_DOCUMENTS_SUCCESS,
    FETCH_WMS_STATUS_SUCCESS,
    UPDATE_RATIONAL_DOCUMENT,
    FETCH_ADDITIONAL_DOCUMENT_SUCCESS,
    UPDATE_ADDITIONAL_DOCUMENT,
    UPDATE_LOADER_SHOW_STATUS,
    FETCH_CANDIDATE_FOLDERS_SUCCESS,
    UPDATE_CANDIDATE_GRADE_SUCCESS,
    UPDATE_CANDIDATE_EVIDENCE_UPLOADED_DELETED_FILE,
    EVIDENCE_SUBMISSION_SUCCESS,
    FILTER_CANDIDATE_SETWISE,
    SET_SELECTED_CENTRE,
    GET_CONTEXT_DATA_ERROR,
    UPDATE_WMS_AFTER_UPLOAD,
    FETCH_EVIDENCE_FILES_FAILURE

} from '../action-types';
import { IInitialState } from './model';
import _ from 'lodash';
import { Constant } from '../../config/constant';

const initialState: IInitialState = {
    currentUserId: '',
    currentCentreId: '',
    activeTab: 1,
    centresList: [],
    syllabusList: [],
    selectedSyllabus: {},
    selectedCentreName: '',
    selectedCentre: '',
    selectedCandidateWithEvidence: {},
    candidateIdentifier: "",
    candidateGenericRationaleDocuments: [],
    candidateGenericWMSDocuments: [],
    firstSampleSubmittedDate: "",
    secondSampleSubmittedDate: "",
    SAGFileInfo: [],
    candidateAdditionalDocuments: [],
    additionalDocTimeStampStatus: [],
    alertData: {
        isShowAlert: false,
        alertColor: '',
        alertMessage: ''
    },
    isShowLoader: false
};


const restructurecandidateRationaleDocuments = (elem: any) => {
    return [{
        documentName: elem['rationale-document'],
        documentLocation: elem['rationale-document-location'],
        submittedDate: elem['sample-submitted-date'],
        setNumber: 1
    },
    {
        documentName: elem['second-rationale-document'],
        documentLocation: elem['second-rationale-document-location'],
        submittedDate: elem['second-sample-submitted-date'],
        setNumber: 2
    }]
}

const restructurecandidateWMSDocuments = (elem: any) => {

    return [{
        wmsUploadName: elem['first-wms-upload-name'],
        wmsUploadLocation: elem['first-wms-upload-location'],
        wmsUploadSubmittedDate: elem['first-wms-submitted-date'],
        setNumber: 1

    },
    {
        wmsUploadName: elem['second-wms-upload-name'],
        wmsUploadLocation: elem['second-wms-upload-location'],
        wmsUploadSubmittedDate: elem['second-wms-submitted-date'],
        setNumber: 2
    }]

}

const restructureSAGDocument = (elem: any) => {
    return [{
        documentName: elem['syllabus-grade'],
        documentLocation: elem['syllabus-grade-location'],
    }]
}


const generateNewSyllabus = (elem: any, syllabusStatusres = []) => {
    return {
        syllabusNumber: elem['centre']['syllabus-no'],
        syllabusName: elem['centre']['syllabus-name'],
        syllabusStatus: _.get(_.find(syllabusStatusres, ['syllabus', elem['centre']['syllabus-no']]), 'message', ''),
        candidateDetails: [{
            setNumber: elem['centre']['set-number'],
            candidateNumber: elem['centre']['candidate-no'],
            candidateUserNumber: elem['centre']['candidate-user-number'],
            candidateName: elem['centre']['candidate-name'],
            gradeSet: elem['centre']['syllabus-gradeset']?.split(',')
        }]
    }
}

const restructureSyllabusDetails = (payload: any) => {
    let arr: any = [], newObj;
    return payload.reduce((_acc: any, _elem: any) => {
        if (arr.length) {
            const currentIndex = arr.findIndex((each: any) => each.syllabusNumber === _elem['centre']['syllabus-no']);
            if (currentIndex === -1) {
                newObj = generateNewSyllabus(_elem);
                arr = [...arr, newObj];
            } else {
                arr[currentIndex].candidateDetails.push({
                    setNumber: _elem['centre']['set-number'],
                    candidateNumber: _elem['centre']['candidate-no'],
                    candidateUserNumber: _elem['centre']['candidate-user-number'],
                    candidateName: _elem['centre']['candidate-name'],
                    gradeSet: _elem['centre']['syllabus-gradeset']?.split(',')
                });
            }

        } else {
            newObj = generateNewSyllabus(_elem);
            arr = [...arr, newObj];
        }
        return arr;
    }, arr)
}

const appendEvidenceData = (selectedCandidate: any, response: any) => {
    // response.forEach((elem: any) => {
    //     const index = selectedCandidate.evidenceList.findIndex((each: any) => each['candidate-evidence-file-location'] === elem['candidate-evidence']['candidate-evidence-file-location'])
    //     if (index !== -1) {
    //         selectedCandidate.evidenceList[index]['candidate-evidence-grade'] = elem['candidate-evidence']['candidate-evidence-grade']
    //         selectedCandidate.evidenceList[index]['candidate-evidence-id'] = elem['candidate-evidence']['candidate-evidence-id']
    //     }
    // })
    return selectedCandidate;
}

const updateCandidateEvidence = (currentState: any, actionPayload: any) => {
    const { selectedCandidateWithEvidence } = currentState;
    const { evidenceList } = selectedCandidateWithEvidence;
    evidenceList[actionPayload.evidenceIndex].uploadedProps.uploadedFiles = actionPayload.fileNames;

    return { ...selectedCandidateWithEvidence };
}

const updateSyllabusStatus = (selectedSyllabus: any, status: string) => {
    switch (selectedSyllabus.syllabusStatus) {
        case Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.FIRST_SAMPLE_REQUESTED:
            selectedSyllabus.syllabusStatus = Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.FIRST_SAMPLE_SUBMITTED;
            return selectedSyllabus;

        case Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_REQUESTED:
            selectedSyllabus.syllabusStatus = Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_SUBMITTED;
            return selectedSyllabus;

        case Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.FIRST_SAMPLE_FURTHER_REQUESTED:
            selectedSyllabus.syllabusStatus = Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.FIRST_SAMPLE_FURTHER_SUBMITTED;
            return selectedSyllabus;

        case Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_FURTHER_REQUESTED:
            selectedSyllabus.syllabusStatus = Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_FURTHER_SUBMITTED;
            return selectedSyllabus;

        default: return selectedSyllabus;

    }

}

const setContextData = (state = initialState, action: any) => {
    const newState = { ...state }
    switch (action.type) {
        case GET_CENTRES_DATA_SUCCESS: {
            return {
                ...newState,
                centresList: action.payload
            }
        }

        case SET_SELECTED_CENTRE: {
            const selectedIndex = newState.centresList?.find((each: any) => each.centreno === action.payload);
            return {
                ...newState,
                selectedCentreName: selectedIndex,
                selectedCentre: action.payload
            }
        }

        case FILTER_CANDIDATE_SETWISE: {

            const selectedSyllabusId = (newState.selectedSyllabus as any).syllabusNumber,
                syllabusList = [...newState.syllabusList],
                selectedSyllabusElem = syllabusList.find((item: any) => item.syllabusNumber === selectedSyllabusId),
                filteredArray = selectedSyllabusElem?.candidateDetails?.filter((elem: any) => elem.setNumber === Number(action.setNumber)),
                selectedSyllabus = { ...newState.selectedSyllabus };
            selectedSyllabus.candidateDetails = filteredArray

            return {
                ...newState,
                activeTab: Number(action.setNumber),
                selectedSyllabus: { ...selectedSyllabus }
            }
        }


        case GET_CONTEXT_DATA_SUCCESS: {
            return {
                ...newState,
                syllabusList: restructureSyllabusDetails(action.payload)
            }
        }

        case GET_CONTEXT_DATA_ERROR: {
            return {
                ...newState,
                syllabusList: []
            }
        }

        case GET_CANDIDATE_LIST: {
            const syllabusList = _.cloneDeep(newState.syllabusList);
            const selectedItem = syllabusList.find((each: any) => each.syllabusNumber === action.syllabusId);
            console.log(selectedItem);
            const activeTab = newState.activeTab;
            const filterArray = selectedItem?.candidateDetails.filter((eachElem: any) => eachElem.setNumber === activeTab);
            if (selectedItem && (selectedItem as any).candidateDetails) {
                (selectedItem as any).candidateDetails = filterArray;
            }
            return {
                ...newState,
                selectedSyllabus: { ...selectedItem }
            }
        }
        case SET_CURRENT_CENTRE_ID:
            return {
                ...newState,
                currentCentreId: action.payloa
            }

        case SET_CURRENT_USER_ID:
            return {
                ...newState,
                currentUserId: action.payload
            }
        case SET_CANDIDATE_IDENTIFIER:
            return {
                ...newState,
                candidateIdentifier: action.candidateIdentifier
            }
        case TOGGLE_ALERT: {
            return {
                ...newState,
                alertData: { ...action.payload.alertInfo }
            };
        }

        case FETCH_CANDIDATE_DOCUMENTS_SUCCESS: {
            return {
                ...newState,
                candidateGenericRationaleDocuments: restructurecandidateRationaleDocuments(action.payload[0]['syllabus-information']),
                candidateGenericWMSDocuments: restructurecandidateWMSDocuments(action.payload[0]['syllabus-information']),
                SAGFileInfo: restructureSAGDocument(action.payload[0]['syllabus-information']),
                firstSampleSubmittedDate: action.payload[0]['syllabus-information']['sample-submitted-date'],
                secondSampleSubmittedDate: action.payload[0]['syllabus-information']['second-sample-submitted-date']
            }
        }

        case FETCH_EVIDENCE_FILES_FAILURE: {
            const selectedCandidateWithEvidence = _.cloneDeep(newState.selectedCandidateWithEvidence);
            (selectedCandidateWithEvidence as any).evidenceList = [];
            return {
                ...newState,
                selectedCandidateWithEvidence: {}
            }
        }

        case FETCH_WMS_STATUS_SUCCESS: {
            const selectedSyllabusFromStore = _.cloneDeep(newState.selectedSyllabus);
            (selectedSyllabusFromStore as any).syllabusStatus = action.payload[0]['wms-status']['first-wms-status']

            console.log('selectedSyllabusfrom store:', selectedSyllabusFromStore)
            return {
                ...newState,
                selectedSyllabus: selectedSyllabusFromStore
            }
        }

        case UPDATE_WMS_AFTER_UPLOAD: {
            const activeTab = Number(newState.activeTab);
            const WMSArray = _.cloneDeep(newState.candidateGenericWMSDocuments);
            WMSArray[activeTab - 1].wmsUploadName = action.fileName;
            return {
                ...newState,
                candidateGenericWMSDocuments: WMSArray
            }
        }

        case FETCH_ADDITIONAL_DOCUMENT_SUCCESS: {
            console.log('additional in reducer:', action.payload);
            return {
                ...newState,
                candidateAdditionalDocuments: action.payload.map((each: any) => each['additional-materials'])
            }
        }

        case UPDATE_ADDITIONAL_DOCUMENT: {
            return {
                ...newState,
                candidateAdditionalDocuments: action.payload
            }
        }

        case UPDATE_LOADER_SHOW_STATUS: {
            return {
                ...newState,
                isShowLoader: action.payload
            }
        }

        case FETCH_CANDIDATE_FOLDERS_SUCCESS: {
            const selectedCandidate = action.basicStructForEvidence;
            return {
                ...newState,
                selectedCandidateWithEvidence: { ...appendEvidenceData(selectedCandidate, action.payload) }
            }
        }

        case UPDATE_CANDIDATE_GRADE_SUCCESS:
            console.log('action.payload for grade sucess:', action.payload, action.status);
            const index = (newState.selectedCandidateWithEvidence as any).evidenceList.findIndex((res: { [x: string]: any; }) => res['candidate-evidence-file-location'] === action.payload['file-location']);
            console.log(index);
            const newEvidence = { ...newState.selectedCandidateWithEvidence };
            (newEvidence as any).evidenceList[index]['candidate-evidence-grade'] = action.payload.grade
            return {
                ...newState,
                selectedCandidateWithEvidence: newEvidence
            }

        case UPDATE_CANDIDATE_EVIDENCE_UPLOADED_DELETED_FILE: {
            return {
                ...newState,
                selectedCandidateWithEvidence: updateCandidateEvidence(newState, action.payload)
            }
        }

        case EVIDENCE_SUBMISSION_SUCCESS: {
            const selectedSyllabus = { ...newState.selectedSyllabus }
            selectedSyllabus.syllabusStatus = 'Submitted'


            return {
                ...newState,
                selectedSyllabus: selectedSyllabus
            }
        }

        default: {
            return state;
        }
    }
};

export default setContextData