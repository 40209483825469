import APIService from '../../services/apiService';
import { downloadWMSFileFailure } from '../actions';
import { call, put } from "redux-saga/effects";

interface IDownload {
    data: {
        'download-url': string
    }
}

export default function* downloadWMSFile(action: any) {
    let res: IDownload;
    try {
        console.log(action.payload);
        res = yield call([APIService, 'downloadWMSFile'], action.payload);
        window.open(res.data["download-url"], '_blank');
    }
    catch {
        yield put(downloadWMSFileFailure())
    }
}