import { FC, Fragment, useEffect, useState } from 'react';
import CandidateInstructions from '../Candidates/candidates-instructions';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import {
    Col,
    Row,
    CardBody,
    Card,
    Container,
    CardText
} from 'reactstrap';
import { useHistory, useRouteMatch } from "react-router-dom";
import DateConverter from '../../helper/date-format-converter';


const CandidateList: FC = (props: any): JSX.Element => {



    const history = useHistory();
    const { url } = useRouteMatch();

    const [rationaleDoc, setRationaleDoc] = useState({});
    const [rationleSubmissionDate, setRationaleSubmissionDate] = useState('');

    useEffect(() => {
        setRationaleDoc(props.candiateRationalDocuments.find((each: any) => (Number(each.setNumber) === Number(props.activeTab)) && each.documentName !== null));
        console.log((rationaleDoc as any)?.submittedDate);
    }, [props.activeTab, props.candiateRationalDocuments])

    useEffect(() => {
        if (((rationaleDoc as any)?.submittedDate)&&((rationaleDoc as any)?.submittedDate !== null)){
            const submittedDate = DateConverter.dateConverter((rationaleDoc as any)?.submittedDate);
            setRationaleSubmissionDate(submittedDate)
        }
    }, [(rationaleDoc as any)?.submittedDate]);

    const visitRationaleDocument = () => {
        history.push({
            pathname: `${url}/rationale-document`,
            state: {
                fromPath: props.location.pathname
            }
        });
    }

    const visitAdditionalMaterial = () => {
        history.push({
            pathname: `${url}/additional-document`,
            state: {
                fromPath: props.location.pathname
            }
        });
    }

    const candidateEvidenceList = (identifier: string) => {
        history.push({
            pathname: `${url}/candidate-evidence/${identifier}`,
            state: {
                fromPath: props.location.pathname
            }
        });
    }

    return (

        <Fragment>
            <CandidateInstructions
                centreId={props.centreId}
                centreName={props.selectedcentreName}
                syllabusNumber={props.selectedSyllabus?.syllabusNumber}
                syllabusName={props.selectedSyllabus?.syllabusName}
                candidateCount={props.selectedSyllabus?.candidateDetails?.length} />
            {props.selectedSyllabus?.candidateDetails && <Container className="candidate-list-container">
                <Card className="card-row mb-2">
                    <CardBody className="card-body pxy-2 hoverRow" onClick={() => visitRationaleDocument()}>
                        <Row className="align-items-center">
                            <Col md={10}>
                                <div className="h5 font-weight-bold centerName">Rationale document</div>
                                <span className="file-count">{rationaleDoc ? 1 : 0} file(s)</span><br />
                                {rationleSubmissionDate !== '' && <div className="file-count">Submitted on {rationleSubmissionDate}</div>}
                                 {rationleSubmissionDate === '' && <div className="file-count">Submitted on: No date available</div>}
                            </Col>
                            <Col md={2}>
                                <button className="btn startBtn">
                                    <span className="font-weight-bold mr-3">VIEW</span>
                                    <FontAwesomeIcon className="fileUpload" icon={faEye} />
                                </button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card className="card-row mb-2">
                    <CardBody className="card-body pxy-2 hoverRow" onClick={() => visitAdditionalMaterial()}>
                        <Row className="align-items-center">
                            <Col md={10}>
                                <h3 className="h5 font-weight-bold centerName">Additional documents</h3>
                                <span className="file-count">{props.candidateAdditionalDocuments.length} file(s)</span><br />

                                {rationleSubmissionDate !== '' && <div className="file-count">Submitted on {rationleSubmissionDate}</div>}
                                 {rationleSubmissionDate === '' && <div className="file-count">Submitted on: No date available</div>}
                            </Col>
                            <Col md={2}>
                                <button className="btn startBtn">
                                    <span className="font-weight-bold mr-3">VIEW</span>
                                    <FontAwesomeIcon className="fileUpload" icon={faEye} />
                                </button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {props.selectedSyllabus?.candidateDetails && props.selectedSyllabus?.candidateDetails.map((value: any, index: number) => {
                    return (
                        <Card className="card-row mb-2" key={index}>
                            <CardBody className="card-body pxy-2" onClick={() => candidateEvidenceList(value.candidateNumber)}>
                                <Row className="align-items-center">
                                    <Col md={10}>
                                        <h3 className="h5 font-weight-bold centerName">{value.candidateName}</h3>
                                        <div className="font-weight-600 grayText font-size18">{value.candidateUserNumber}</div>
                                        {/* <span className="file-count">Submitted on  </span> */}
                                    </Col>
                                    <Col md={2}>
                                        <button className="btn startBtn">
                                            <span className="font-weight-bold mr-3">VIEW</span>
                                            <FontAwesomeIcon className="fileUpload" icon={faEye} />
                                        </button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    )
                })}
            </Container>}
            {(!props.selectedSyllabus?.candidateDetails || props.selectedSyllabus?.candidateDetails.length <= 0) && <Card body className="text-center rounded">
                <CardText>No related Candidate to display for this Syllabus</CardText>
            </Card>
            }
        </Fragment>
    )
}

const mapStateToProps = (state: any) => {
    return {
        activeTab: state.setContextData.activeTab,
        selectedSyllabus: state.setContextData?.selectedSyllabus,
        centreId: state.setContextData?.selectedCentre,
        selectedcentreName: state.setContextData?.selectedCentreName?.centrename,
        candidateAdditionalDocuments: state.setContextData?.candidateAdditionalDocuments,
        candiateRationalDocuments: state.setContextData?.candidateGenericRationaleDocuments
    }
}


export default connect(mapStateToProps, null)(CandidateList);