
import React, { FC, useEffect, useState } from 'react';
import { SupmitPopup } from './submit-modal';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Spinner
} from 'reactstrap';
import Uploader from '../../Shared/Uploader/uploader';
import { Constant } from '../../../config/constant';
import { toggleAlertShowStatus, updateWmsAfterUpload, fetchCandidateDocuments } from '../../../redux-building-blocks/actions';
import _ from 'lodash';

interface UploadModalProps {
  centreName: string;
  syllabusName: string;
  syllabusNumber: string;
  candidateCount: string;
  selectedcentreNo: string;
  showModalFlag: boolean;
  setUploadModalState: any;
  toggleAlertShowStatus: any;
  updateWmsAfterUpload: any;
  fetchCandidateDocuments: any;
  candidateGenericWMSDocuments: any;
  successCallback: any;
  getPreSignedUploadURl: any;
  errorCallback: any;
  fileDeleteHandler: any;
}

const UploadModal: FC<UploadModalProps> = ({
  centreName,
  syllabusName,
  syllabusNumber,
  candidateCount,
  selectedcentreNo,
  showModalFlag,
  setUploadModalState,
  updateWmsAfterUpload,
  toggleAlertShowStatus,
  fetchCandidateDocuments,
  candidateGenericWMSDocuments,
  successCallback,
  getPreSignedUploadURl,
  errorCallback,
  fileDeleteHandler
}): JSX.Element => {
  console.log('in upload modal');
  const [showModal, setShow] = useState(false);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [uploaderProps, setUploaderProps] = useState({
    noOfMaxFile: 1,
    fileFormatAndSizeSupported: Constant.WMS_ALLOWED_FILE_TYPE_SIZE
  });

  const handleLoader = (loaderState: boolean) => {
    setIsShowLoader(loaderState);
  }

  const handleClose = () => {
    setShow(false);
    setUploadModalState(false);
  }

  const handleShow = () => {
    setShow(true);
  }

  useEffect(() => {
    if (showModalFlag) {
      handleShow();

    }
  }, [showModalFlag]);


  return (
    <React.Fragment>
      <SupmitPopup
        className="bg-white"
        isOpen={showModal}>
        {isShowLoader && <div id="overlay">
          <Spinner color="primary" className="elem-to-center" style={{ width: '5rem', height: '5rem' }} type="grow" />
        </div>}
        <Row className="pb-3">
          <Col>
            <div className="font-weight-bold centerName">{centreName} {selectedcentreNo} - {syllabusName} </div> 
            <div className="font-weight-bold grayText"><span className='igcse-label'>{syllabusNumber}</span> | <span className='candidate-number'>{candidateCount} {candidateCount === '1' ? 'candidate' : 'candidates'}</span></div>
          </Col>
          <FontAwesomeIcon icon={faTimes} className="grayText cancelModal" onClick={() => handleClose()} />
        </Row>
        <p className="font-weight-bold pt-2">QA Report already exists</p>
        <p>There is an existing file that was previously uploaded. If you upload a new QA Report, the previous report will be overridden.</p>
          <p className="pt-4">
          Are you sure you want to upload a new report?
          </p>
        <Row>
          <button className="btn ml-1" onClick={() => handleClose()}>
            <span className="font-weight-bold grayText">Cancel</span>
          </button>
          <Uploader {...uploaderProps}
            inModal={true}
            successCallback={successCallback}
            getPreSignedUploadURl={getPreSignedUploadURl}
            errorCallback={errorCallback}
            fileDeleteHandler={fileDeleteHandler}
            closeModal={handleClose}
            handleModalLoader={handleLoader} />
        </Row>
      </SupmitPopup>
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => {
  return {
    candidateGenericWMSDocuments: state.setContextData.candidateGenericWMSDocuments,
  }
}

const mapDispatchToProps = {
  toggleAlertShowStatus,
  fetchCandidateDocuments,
  updateWmsAfterUpload
}



export default connect(mapStateToProps, mapDispatchToProps)(UploadModal);