import APIService from '../../services/apiService';
import { fetchCandidateDocumentsError, fetchCandidateDocumentsSuccess, fetchAdditionalDocumentsSuccess } from '../actions';
import { call, put } from "redux-saga/effects";

interface IDocument {
    "rationale-document": string;
    "rationale-document-location": string;
    "sample-submitted-date": string;
    "syllabus-grade": string;
    "syllabus-grade-location": string;
}
interface ICandidateDocument {
    data: {
        data: IRationalDocuments
    }
}

interface IRationalDocuments {
    "syllabus-information": IDocument[]
}
interface IDocDetails {
    "file-name": string;
    "file-type": string,
    "submitted-date": string;
}
interface IDocuments {
    'additonal-materials': IDocDetails
}
interface IAdditionalResponse {
    data: {
        data: IDocuments
    }
}

const resp = { data:  {
    "data": [
        {
            "syllabus-information": {
                "rationale-document": "123.pdf",
                "rationale-document-location": "BD001/2081/",
                "sample-submitted-date": "2021-05-04T14:21:51.188425",
                "syllabus-grade": "grades-test.rtf",
                "syllabus-grade-location": "BD001/2081/"
            }
        }
    ]
} 
}

export default function* getCandidateDocuments(action: any) {
    let res: ICandidateDocument;
    let additionalResponse: IAdditionalResponse;
    try {
        res = yield call([APIService, 'fetchCandidateDocuments'], action.centreId,action.syllabusId);
        additionalResponse = yield call([APIService, 'fetchAdditionalDocuments'], action.centreId,action.syllabusId);
        console.log('additional response::', additionalResponse);
        yield put(fetchCandidateDocumentsSuccess(res.data.data));
        console.log(res.data.data);
        yield put(fetchAdditionalDocumentsSuccess(additionalResponse.data.data));
    }
    catch {
        yield put(fetchCandidateDocumentsError())
    }
}