import React, { useEffect } from 'react';
import './App.css'
import Header from './components/Shared/header/header';
import { PageNotFound } from './components/Page-Not-Found/page-not-found';
import CentreList from './components/centre-list';
import Syllabus from './components/syllabus';
import APIService from './services/apiService';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import { connect } from 'react-redux';
import _ from 'lodash';
import { Alert, Spinner } from 'reactstrap';
import {
  fetchCentresData,
  setCurrentCentreId,
  setCurrentUserId,
  toggleAlertShowStatus
} from './redux-building-blocks/actions';

import jwtHelper from './helper/jwt-helper'


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const App = (props: any): JSX.Element => {
  let query = useQuery();
  if (window.location.href?.split('#')[1]) {
    window.location.href = jwtHelper.modifyRedirectionURL();

  }

  const token = (!_.isEmpty(query.get("id_token"))) ? query.get("id_token") :
    (!_.isEmpty(jwtHelper.getJwtToken())) ? jwtHelper.getJwtToken() : null;

  useEffect(() => {
    if (!_.isEmpty(token)) {
      jwtHelper.setJwtToken(token as string);
      jwtHelper.hideToken();
      initiateCentresData();
      
      // To handle Token Exp time //increased this to 10mins interval
      const interval = setInterval(async () => {
        await APIService.fetchCentresData();
      }, 600000);
      return () => clearInterval(interval);
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);


  useEffect(() => {
    if (props.isShowAlert) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        onDismiss();
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isShowAlert]);

  const onDismiss = () => {
    props.toggleAlertShowStatus({
      isShowAlert: false,
      alertColor: '',
      alertMessage: ''
    })
  }

  const initiateCentresData = () => {
    props.fetchCentresData();
    //jwtHelper.getDecodedToken();
    // props.setCurrentCentreId(jwtHelper.getCentreIdFromToken());
    //props.setCurrentUserId(jwtHelper.getUserIdFromToken());
  }

  return (
    <Router>
      <main>
        <React.Fragment>
          <Header />
          {props.isShowLoader && <div id="overlay">
            <Spinner color="primary" className="elem-to-center" style={{ width: '5rem', height: '5rem' }} type="grow" />
          </div>}
          <Alert color={props.alertColor} isOpen={props.isShowAlert} toggle={onDismiss}>
            {props.alertMessage}
          </Alert>
          <Switch>
            <Route exact path={['/index.html', '/']} component={CentreList} />
            <Route path='/centre/:id' component={Syllabus} />
            <Route component={PageNotFound} />
          </Switch>
        </React.Fragment>
      </main>
    </Router>
  );
}

const mapStateToProps = (state: any) => {
  return {
    alertColor: state.setContextData.alertData.alertColor,
    isShowAlert: state.setContextData.alertData.isShowAlert,
    alertMessage: state.setContextData.alertData.alertMessage,
    isShowLoader: state.setContextData.isShowLoader
  }
}

const mapDispatchToProps = {
  fetchCentresData,
  setCurrentCentreId,
  setCurrentUserId,
  toggleAlertShowStatus
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)