import APIService from '../../services/apiService';
import { evidenceSubmissionSuccess, evidenceSubmissionFailure } from '../actions';
import { call, put } from "redux-saga/effects";

interface ISubmit {
    data: {
        data: any
    }
}

export default function* evidenceSubmission(action: any) {
    let res: ISubmit;
    try {
        console.log("submission payload", action.payload);
       res = yield call([APIService, 'submitEvidence'], action.payload);
        console.log('status res', res);
        yield put(evidenceSubmissionSuccess(res, action.payload))
    }
    catch {
        yield put(evidenceSubmissionFailure())
    }
}