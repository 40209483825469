import APIService from '../../services/apiService';
import { updateCandidateGradesSuccess, updateCandidateGradesFailure} from '../actions';
import { call, put } from "redux-saga/effects";

interface IFolders {
    data: {
        data: any
    }
}

export default function* updateCandidateGrade(action: any) {
    let res: IFolders;
    try {
        res = yield call([APIService, 'updateCandidateGrade'], action.payload);
        yield put(updateCandidateGradesSuccess(res, action.payload))
    }
    catch {
        yield put(updateCandidateGradesFailure())
    }
}