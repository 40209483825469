import React, { FC, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _, { isEmpty } from 'lodash';
import {
    Col,
    Row,
    CardBody,
    Card,
    Container, Button,
    CardText
} from 'reactstrap';
import { fetchCandidateList, setCandidateIdentifier, fetchCandidateFolderLocations, fetchEvidenceLevelFiles, toggleAlertShowStatus, toggleLoaderShowStatus } from '../../redux-building-blocks/actions';
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import CandidateEvidenceDocuments from './candidate-evidence-documents';
import DateConverter from '../../helper/date-format-converter';

const CandidateEvidence: FC = (props: any): JSX.Element => {
    const { candidateId }: any = useParams();
    const history = useHistory();
    let { url } = useRouteMatch();
    const evidenceSections = url.split('/');
    evidenceSections.pop();
    // url.split('/').join('/');
    url = evidenceSections.join('/');
    console.log('id:::', evidenceSections.join('/'));
    const [candidate, setcandidate] = useState({} as any);
    const [disablePrevious, setDisablePrevious] = useState(false);
    const [disableNext, setDisableNext] = useState(false);
    const [candidateDetails, setCandidateDetails] = useState({
        candidateName: '',
        candidateNumber: '',
        candidateUserNumber: '',
        evidenceList: []
    });
    const [submissionDate, setSubmissionDate] = useState('');

    // useEffect(() => {
    //     if (props.rationalDocuments.length && props.activeTab) {
    //         const index = Number(props.activeTab);
    //         setSubmissionDate(props.rationalDocuments[index - 1].submittedDate);
    //     }

    // }, [props.rationalDocuments, props.activeTab])

    useEffect(() => {
        if (_.isEqual(props.candidateIdentifier, parseInt(candidateId)) &&
            !_.isEmpty(props.selectedSyllabus)) {
            const selectedcandidate = props.candidateList?.find((candidate: any) =>
                candidate.candidateNumber === props.candidateIdentifier
            ),
                candidatePayload = {
                    candidateId: props.candidateIdentifier,
                    syllabusId: props.selectedSyllabus.syllabusNumber,
                    centreNumber: props.centreDetails
                },
                index = props.candidateList?.findIndex((each: any) => each.candidateNumber === props.candidateIdentifier);
            setDisablePrevious(!index);
            setDisableNext(!!(index === props.candidateList?.length - 1))
            setcandidate(selectedcandidate);
            if (candidatePayload.candidateId && candidatePayload.syllabusId) {
                props.fetchCandidateFolderLocations(candidatePayload);
            }
        } else {
            props.setCandidateIdentifier(parseInt(candidateId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.candidateIdentifier, props.selectedSyllabus]);

    useEffect(() => {
        // if (!_.isEmpty(props.selectedCandidateWithEvidence)) {
        setCandidateDetails(props.selectedCandidateWithEvidence)
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedCandidateWithEvidence]);

    // useEffect(() => {
    //     setSubmissionDate('');
    //     if ((candidateDetails?.evidenceList?.[0] as any)?.uploadedProps?.uploadedFileList.length &&
    //         (candidateDetails?.evidenceList?.[0] as any)?.uploadedProps?.uploadedFileList[0]['file-uploaded-date']) {
    //         const fetchedDate = (candidateDetails?.evidenceList[0] as any).uploadedProps?.uploadedFileList[0]['file-uploaded-date'];
    //         const submittedDate = DateConverter.dateConverter(fetchedDate);
    //         setSubmissionDate(submittedDate)
    //     }
    // }, [(candidateDetails?.evidenceList?.[0] as any)?.uploadedProps?.uploadedFileList]);

    useEffect(() => {
        setSubmissionDate('');
        if(props.activeTab===1 && props.firstSampleSubmittedDate!==null){
            const submittedDate = DateConverter.dateConverter(props.firstSampleSubmittedDate);
            setSubmissionDate(submittedDate)  
        }
        else if(props.activeTab===2 && props.secondSampleSubmittedDate!==null){
            const submittedDate = DateConverter.dateConverter(props.secondSampleSubmittedDate);
            setSubmissionDate(submittedDate)  
        }
        
    }, [props.firstSampleSubmittedDate,props.secondSampleSubmittedDate]);


    const prevCandidate = () => {
        const index = props.candidateList?.findIndex((obj: any) => obj.candidateNumber === candidate.candidateNumber)
        if (index <= props.totalCandidates - 1) {

            props.setCandidateIdentifier(props.candidateList[index - 1].candidateNumber);
            history.push({
                pathname: `${url}/${props.candidateList[index - 1].candidateNumber}`,
                state: {
                    fromPath: props.location.pathname
                }
            });
        }

    }

    const nextCandidate = () => {
        const index = props.candidateList?.findIndex((obj: any) => obj.candidateNumber === candidate.candidateNumber)
        console.log(props.candidateList[index + 1].candidateNumber);
        if (index <= props.totalCandidates - 1) {

            props.setCandidateIdentifier(props.candidateList[index + 1].candidateNumber);
            history.push({
                pathname: `${url}/${props.candidateList[index + 1].candidateNumber}`,
                state: {
                    fromPath: props.location.pathname
                }
            });
        }
    }


    console.log('evidence:', candidateDetails)
    return (
        <Fragment>
            <Container fluid className='container-wrapper'>
                <div className='wrapper'>
                    <div>
                        <p className='theme-label centre-head'>Centre {props.centreDetails}-{props.selectedSyllabus.syllabusName}</p>
                        <p className='syllabus-subtext'><span className='igcse-label'>{props.selectedSyllabus.syllabusNumber}</span> | <span className='candidate-number'>{props.totalCandidates} candidates</span></p>
                    </div>
                    <div>
                        <br></br>
                        <div className='student-instruction'>
                        View candidate grades and download candidate evidence by clicking on Download.
                        <br/> Click on 'Next candidate' to view the next candidate in the sample.
                   </div>
                    </div>
                </div>

            </Container>
            <Container className="candidate-list-container mb-35">
                <Row className="align-items-center">
                    <div className="mr-10">
                        <Button disabled={disablePrevious} className="prev-candidate" onClick={() => prevCandidate()}>
                            <span className="btn-status-text">Previous candidate</span>
                        </Button>
                    </div>
                    <div>
                        <Button disabled={disableNext} className="prev-candidate ml-1" onClick={() => nextCandidate()}>
                            <span className="btn-status-text">Next candidate</span>
                        </Button>
                    </div>
                </Row>
                <br></br>
                {(!_.isEmpty(candidateDetails.candidateName) ||
                    !_.isEmpty(candidateDetails.candidateUserNumber)) && <Card className="borderTop mt-1">
                        <CardBody className="card-body pxy-2">
                            <Row
                                className="align-items-center">
                                <Col md={8}>
                                    <div className="h5 font-weight-bold centerName">{candidateDetails.candidateName}</div>
                                    <div className="font-weight-500 grayText font-size18">{candidateDetails.candidateUserNumber}</div>
                                    {submissionDate !== '' && <div className="font-weight-500 grayText font-size18">Submitted on {submissionDate}</div>}
                                    {submissionDate === '' && <div className="font-weight-500 grayText font-size18">Submitted on: No date available</div>}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>}
                {(!candidateDetails.evidenceList || candidateDetails.evidenceList.length <= 0) ? (<Card body className="text-center rounded">
                    <CardText>Evidence folders/files can't be fetched</CardText>
                </Card>) : (
                    candidateDetails.evidenceList.map((eachItem: any, index: number) => {
                        return (<CandidateEvidenceDocuments
                            key={`CandidateEvidence-${index}`}
                            eachEvidenceItem={eachItem}
                            index={index} />)
                    })
                )
                }
            </Container>
        </Fragment>
    )
}

const mapStateToProps = (state: any) => {
    return {
        totalCandidates: state.setContextData.selectedSyllabus?.candidateDetails?.length,
        candidateList: state.setContextData.selectedSyllabus?.candidateDetails,
        candidateIdentifier: state.setContextData?.candidateIdentifier,
        selectedSyllabus: state.setContextData?.selectedSyllabus,
        centreDetails: state.setContextData?.selectedCentre,
        selectedCandidateWithEvidence: state.setContextData?.selectedCandidateWithEvidence,
        rationalDocuments: state.setContextData?.candidateGenericRationaleDocuments,
        activeTab: state.setContextData?.activeTab,
        firstSampleSubmittedDate: state.setContextData?.firstSampleSubmittedDate,
        secondSampleSubmittedDate: state.setContextData?.secondSampleSubmittedDate,

    }
}

const mapDispatchToProps = {
    fetchCandidateList,
    setCandidateIdentifier,
    fetchCandidateFolderLocations,
    fetchEvidenceLevelFiles,
    toggleLoaderShowStatus,
    toggleAlertShowStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateEvidence);

