import APIService from '../../services/apiService';
import { fetchWMSStatusSuccess, fetchWMSStatusError } from '../actions';
import { call, put } from "redux-saga/effects";

interface ICentres {
    data: {
        data: any;
    }
}

export default function* fetchWMSStatus(action: any) {
    let res: ICentres;
    try {
        res = yield call([APIService, 'fetchWMSStatus'],action.centreId,action.syllabusId);
        console.log(res.data);
        yield put(fetchWMSStatusSuccess(res.data));
    }
    catch {
        yield put(fetchWMSStatusError())
    }
}