import { FC, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faDownload } from '@fortawesome/free-solid-svg-icons';
import {
    Col,
    Row,
    CardBody,
    Card,
    Container,
    CardText
} from 'reactstrap';
import { useHistory } from "react-router-dom";
import { isEmpty } from 'lodash';
import DateConverter from '../../helper/date-format-converter';
import { downloadWMSFile } from '../../redux-building-blocks/actions';



const AdditionalMaterial: FC = (props: any): JSX.Element => {

    const [candidateCount, setCandidateCount] = useState(0);
    const [sampleSubmissionDate, setSampleSubmissionDate] = useState('');

    useEffect(() => {
        if (!isEmpty(props.selectedSyllabus)) {
            setCandidateCount(props.selectedSyllabus.candidateDetails.length)
        }
    }, [props.selectedSyllabus]);
    console.log('selectedSyllabus:::', props.selectedSyllabus);
    console.log('additional documents::', props.additionalDocuments);

    const downloadAdditionalDocument = (fileLocation: string, fileName: string, fileType: string) => {
        props.downloadWMSFile(fileLocation, fileName, fileType)

    }

    useEffect(() => {
        setSampleSubmissionDate('');
        if(props.activeTab===1 && props.firstSampleSubmittedDate!==null){
            const submittedDate = DateConverter.dateConverter(props.firstSampleSubmittedDate);
            setSampleSubmissionDate(submittedDate)  
        }
        else if(props.activeTab===2 && props.secondSampleSubmittedDate!==null){
            const submittedDate = DateConverter.dateConverter(props.secondSampleSubmittedDate);
            setSampleSubmissionDate(submittedDate)  
        }
        
    }, [props.firstSampleSubmittedDate,props.secondSampleSubmittedDate]);

    return (
        <Fragment>
            <Container fluid className='container-wrapper'>
                <div className='wrapper'>
                    <div className='py-2'>
                        <p className='theme-label centre-head'>{props.selectedcentreName} - {props.selectedSyllabus.syllabusName}</p>
                        <p className='syllabus-subtext'><span className='igcse-label'>{props.selectedSyllabus?.syllabusNumber}</span> | <span className='candidate-number'>{candidateCount} {candidateCount === 1 ? 'candidate' : 'candidates'}</span></p>
                        <div className='pt-3 student-instruction'>Review the Additional Documents by clicking on Download.</div>
                    </div>
                </div>
            </Container>
            <Container className="candidate-list-container">
                <Card className="borderTop mt-1">
                    <CardBody className="card-body pxy-2">
                        <Row className="align-items-center">
                            <Col md={8}>
                                <div><p className="theme-label centre-head">Additional documents</p></div>
                                <span className="file-count">{props.additionalDocuments?.length} file(s)</span><br />
                                {sampleSubmissionDate !== '' && <div className="font-weight-500 grayText font-size18">Submitted on {sampleSubmissionDate}</div>}
                                    {sampleSubmissionDate === '' && <div className="font-weight-500 grayText font-size18">Submitted on: No date available</div>}

                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {props.additionalDocuments && props.additionalDocuments?.map((value: any, index: number) => {
                    return (
                        <Card className="borderBottom" key={index}>
                            <CardBody className="card-body pxy-2">
                                <Row className="align-items-center">
                                    <Col md={8} className="font-weight-600">{value['file-name']}</Col>
                                    <Col md={4}>
                                        <button className="btn downloadBtn ml-4"
                                            onClick={() => downloadAdditionalDocument(value['file-location'], value['file-name'], "evidence")}>
                                            <FontAwesomeIcon icon={faDownload} />
                                            <span className="font-weight-bold ml-2">Download</span>
                                        </button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    )
                })}
                {(!props.additionalDocuments || !props.additionalDocuments?.length) && <Card body className="text-center rounded">
                    <CardText>No Additional Documents present for this syllabus</CardText>
                </Card>
                }

            </Container>
        </Fragment>

    )
}

const mapStateToProps = (state: any) => {
    console.log(state.contextData?.selectedSyllabus, state.setContextData?.candidateAdditionalDocuments);
    return {
        activeTab: state.setContextData?.activeTab,
        firstSampleSubmittedDate: state.setContextData?.firstSampleSubmittedDate,
        secondSampleSubmittedDate: state.setContextData?.secondSampleSubmittedDate,
        additionalDocuments: state.setContextData?.candidateAdditionalDocuments,
        selectedSyllabus: state.setContextData?.selectedSyllabus,
        centreId: state.setContextData?.selectedCentre,
        selectedcentreName: state.setContextData?.selectedCentreName?.centrename
    }
}

const mapDispatchToProps = {
    //fetchCandidateDocuments
    downloadWMSFile
}


export default connect(mapStateToProps, mapDispatchToProps)(AdditionalMaterial);