import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import { fetchContextData, setSelectedCentre } from '../redux-building-blocks/actions';
import {
  Col,
  Row,
  CardBody,
  Card,
  Container,
  CardText
} from 'reactstrap'

const CentreList: FC = (props: any): JSX.Element => {

  useEffect(() => {
    console.log('props:', props.centresList);
  }, []);

  const history = useHistory();
  const visitCentre = (index: number) => {
    history.push({
      pathname: `/centre/${props.centresList[index].centreno}`,
      state: {
        fromPath: props.location.pathname
      }
    });
  }
  return (
    <>
      <Container fluid className='container-wrapper'>
        <div className='wrapper'>
          <Col>
            <h4 className="font-weight-bold centerName mb-3">My centres</h4>
            <div className="w-50 student-instruction">Select a centre to view the syllabuses and review and download candidate work.</div>
          </Col>
        </div>
        {/* <div className="container mt-4">
          <Row className="rounded mx-0" md={4}>
            <Col>
              <input type="text" placeholder="Search for a centre" className="mb-4 form-control" />
            </Col>
            <Col
              style={{ minWidth: '64px' }}
              className="align-items-center justify-content-center text-center text-secondary px-3">
              <FontAwesomeIcon icon={faSearch} />
            </Col>
          </Row>
        </div> */}
        {props.centresList && props.centresList.map((value: any, index: number) => {
          return (
            <div className="container mt-4" key={index}>
              <Card className="card-row mb-2" key={index}>
                <CardBody className="card-body pxy-2">
                  <Row className="align-items-center">
                    <Col>
                      <h3 className="h5 font-weight-bold centerName">
                        {value.centrename}
                      </h3>
                    </Col>
                    <Col xs="auto" className="text-right">
                      <button className="btn startBtn" onClick={() => visitCentre(index)}>
                        <span className="font-weight-bold mr-3">VIEW</span>
                        <FontAwesomeIcon className="fileUpload" icon={faEye} />
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          );
        })}
        {(!props.centresList || !props.centresList.length) && <Card body className="text-center rounded">
          <CardText>No Centres</CardText>
        </Card>
        }
      </Container>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    centresList: state.setContextData.centresList
  }
}

const mapDispatchToProps = {
  fetchContextData,
  setSelectedCentre
}

export default connect(mapStateToProps, mapDispatchToProps)(CentreList);