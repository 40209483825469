import APIService from '../../services/apiService';
import { fetchCentresDataSuccess, fetchCentresDataError } from '../actions';
import { call, put } from "redux-saga/effects";

interface ICentres {
    data: {
        data: any;
    }
}

export default function* getCentresData() {
    let res: ICentres;
    try {
        res = yield call([APIService, 'fetchCentresData']);
        console.log(res.data);
        yield put(fetchCentresDataSuccess(res.data));
    }
    catch {
        yield put(fetchCentresDataError())
    }
}