import { FC, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faDownload } from '@fortawesome/free-solid-svg-icons';
import _, { isEmpty } from 'lodash';
import {
    Col,
    Row,
    CardBody,
    Card,
    Container,
    CardText
} from 'reactstrap';
import { useHistory } from "react-router-dom";
import DateConverter from '../../helper/date-format-converter';
import { Constant } from '../../config/constant';
import { downloadWMSFile } from '../../redux-building-blocks/actions';


const RationaleDocument: FC = (props: any): JSX.Element => {

    const [rationalDocumentsData, setRationalDocumentsData] = useState([]);
    const [fileType, setFileType] = useState('evidence');

    useEffect(() => {
        if (!props.location.pathname.includes("rationale-document") && !props.location.pathname.includes("additional-document") && !props.location.pathname.includes("candidate-evidence")) {
            setFileType('wms');
        }
    }, [props.location]);

    useEffect(() => {
        if (props.rationalDocuments.length) {
            setRationalDocumentsData(props.rationalDocuments?.filter((each: any) => each.setNumber === props.activeTab && (each.documentName !== null)))
        }

    }, [props.activeTab, props.rationalDocuments]);

    const downloadRationalDocument = () => {
        console.log('File type - ', props.rationalDocuments[0].documentName.split('.').pop());
        if (props.rationalDocuments[0].documentName !== 'NONE' && props.rationalDocuments[0].documentLocation) {
            props.downloadWMSFile(props.rationalDocuments[0].documentLocation, props.rationalDocuments[0].documentName, fileType)
        } else {
            props.toggleAlertShowStatus({
                isShowAlert: true,
                alertColor: 'info',
                alertMessage: `No Rationale Document present.`
            })
        }
    }

    console.log('rationalDocumentsData::', rationalDocumentsData)
    return (
        <Fragment>
            <Container fluid className='container-wrapper'>
                <div className='wrapper'>
                    <div className='py-2'>
                        <p className='theme-label centre-head'>{props.selectedcentreName} - {props.selectedSyllabus.syllabusName}</p>
                        <p className='syllabus-subtext'><span className='igcse-label'>{props.selectedSyllabus?.syllabusNumber}</span> | <span className='candidate-number'>{props.selectedSyllabus?.candidateDetails?.length} {props.selectedSyllabus?.candidateDetails?.length === 1 ? 'candidate' : 'candidates'}</span></p>
                        <div className='pt-3 student-instruction'>Review the Rationale Document by clicking on Download.</div>
                    </div>
                </div>
            </Container>
            <Container className="candidate-list-container">
                <Card className="borderTop mt-1">
                    <CardBody className="card-body pxy-2">
                        <Row className="align-items-center">
                            <Col md={8}>
                                <div><p className="theme-label centre-head">Rationale document</p></div>
                                <span className="file-count">{rationalDocumentsData?.length} file</span><br />
                                {((rationalDocumentsData?.length!==0) && (rationalDocumentsData[0]['submittedDate'] !== null)) ? (
                                    <span className="file-count">Submitted on {DateConverter.dateConverter(rationalDocumentsData[0]['submittedDate'])} </span>
                                ) : (
                                    <span className="file-count">Submitted on: No date available </span>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {rationalDocumentsData && rationalDocumentsData.map((value: any, index: number) => {
                    return (
                        <Card className="borderBottom" key={index}>
                            <CardBody className="card-body pxy-2">
                                <Row className="align-items-center">
                                    <Col md={8} className="font-weight-600">
                                        {value.documentName}
                                    </Col>
                                    <Col md={4}>
                                        <button className="btn downloadBtn ml-4" onClick={() => downloadRationalDocument()}>
                                            <FontAwesomeIcon icon={faDownload} />
                                            <span className="font-weight-bold ml-2">Download</span>
                                        </button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    )
                })}
                {(!rationalDocumentsData || !rationalDocumentsData?.length) && <Card body className="text-center rounded">
                <CardText>No Rationale Document present for this syllabus</CardText>
                </Card>
                }
            </Container>
        </Fragment>

    )
}

const mapStateToProps = (state: any) => {
    return {
        rationalDocuments: state.setContextData?.candidateGenericRationaleDocuments,
        activeTab: state.setContextData.activeTab,
        additionalDocuments: state.setContextData?.candidateAdditionalDocuments,
        selectedSyllabus: state.setContextData?.selectedSyllabus,
        centreId: state.setContextData?.selectedCentre,
        selectedcentreName: state.setContextData?.selectedCentreName?.centrename

    }
}

const mapDispatchToProps = {
    downloadWMSFile
}


export default connect(mapStateToProps, mapDispatchToProps)(RationaleDocument);