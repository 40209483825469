import React, { FC, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import _ from 'lodash';
import './uploader.css';
import {
    toggleAlertShowStatus
} from './../../../redux-building-blocks/actions';

import PreSignedUrlUploadHelper from './../../../helper/pre-signed-url-upload-helper';

import { getCurrentSyllabusStatus } from './../../../redux-building-blocks/selectors/util-selector'

interface UploaderProps {
    noOfMaxFile?: number;
    buttonText?: string;
    isShowUploadedFileList?: boolean;
    successCallback?: any;
    errorCallback?: any;
    fileList?: Array<string>;
    toggleAlertShowStatus?: any;
    fileFormatAndSizeSupported?: Array<any>;
    getPreSignedUploadURl: any;
    fileDeleteHandler: any;
    index?: any;
    isSubmited?: boolean;
    fileListWithStatus?: any;
    selectedSyllabusStatus?: string;
    inModal?: boolean;
    closeModal?: any;
    handleModalLoader?: any

}

const Uploader: FC<UploaderProps> = ({
    isSubmited,
    toggleAlertShowStatus,
    successCallback,
    getPreSignedUploadURl,
    fileDeleteHandler,
    noOfMaxFile = 10,
    buttonText = 'Upload QA Report',
    isShowUploadedFileList = true,
    fileList = [],
    fileListWithStatus = [],
    fileFormatAndSizeSupported = [{ name: '.pdf', size: null }], //pass size in byte and if null it will not be checked
    errorCallback = null,
    index = 1,
    selectedSyllabusStatus,
    inModal = false,
    closeModal,
    handleModalLoader
}): JSX.Element => {

    const [localButtonText, setLocalButtonText] = useState(buttonText);

    let currentFileList = [...fileList];
    console.log('current file list:::', currentFileList);
    console.log('fileListWithStatus:::', fileListWithStatus);

    useEffect(() => {
        if (noOfMaxFile > 1) {
            setButtonText(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileList]);

    const changeHandler = async (event: any) => {
        const uploadableFile: any = event.target.files[0];
        if (!checkFileFormatAndSize(uploadableFile)) {
            try {
                if (inModal) {
                    handleModalLoader(true);
                }
                const preSignedObject = await getPreSignedUploadURl(uploadableFile.name);
                const { url, fields } = preSignedObject.data.url;
                await PreSignedUrlUploadHelper.uploadFile(url, fields, uploadableFile);
                currentFileList = [uploadableFile.name];
                successCallback(currentFileList, 'UPLOAD', uploadableFile.name);
                if (inModal) {
                    closeModal();
                }

            } catch (error) {
                if (errorCallback) {
                    errorCallback(uploadableFile.name, 'UPLOAD');
                    if (inModal) {
                        handleModalLoader(false);
                        closeModal();
                    }

                }
            }
        }

        event.target.value = '';
    };


    const setButtonText = (newFileCout = 1) => {
        buttonText = 'Upload QA Report'
        setLocalButtonText(buttonText);
    }

    const checkFileFormatAndSize = (file: any) => {
        const fileFormatSupported = _.map(fileFormatAndSizeSupported, 'name');
        let error = (new RegExp('(' + fileFormatSupported?.join('|').replace(/\./g, '\\.') + ')$')).test(_.toLower(file.name)) ? false : true;
        if (error) {
            if (inModal) {
                closeModal();
            }
            toggleAlertShowStatus({
                isShowAlert: true,
                alertColor: 'danger',
                alertMessage: `You can't upload ${file.name} as the file format is not supported`
            })
            // } else if (checkFileSize(file) || sameNameFileExist(file)) {
        } else if (checkFileSize(file)) {
            error = true;
        }
        return error;
    }

    const checkFileSize = (file: any) => {
        const fileAllowedConfig = _.find(fileFormatAndSizeSupported, (o) => _.lowerCase(o.name) === _.lowerCase(file.name.split('.').pop()));
        const error = (file.size && fileAllowedConfig.size && file.size > fileAllowedConfig.size) ? true : false;
        if (error) {
            if (inModal) {
                closeModal();
            }
            toggleAlertShowStatus({
                isShowAlert: true,
                alertColor: 'danger',
                alertMessage: `You can't upload ${file.name} as it is too large`
            })
        }
        return error;
    }

    const sameNameFileExist = (file: any) => {
        const error = _.find(currentFileList, (eachFileName) => _.lowerCase(eachFileName) === _.lowerCase(file.name));
        if (error) {
            toggleAlertShowStatus({
                isShowAlert: true,
                alertColor: 'danger',
                alertMessage: `You can't upload ${file.name} as a file with the same name already exists`
            })
        }
        return error;
    }

    return (
        <React.Fragment>
            {(inModal)
                ? <label htmlFor={`file-upload-${index}`} className={`align-centre uploadModalBtn btn ml-3 cursor-pointer`}>
                   Yes, upload new report
                </label>
                : <label htmlFor={`file-upload-${index}`} className={`align-centre upload-AR position cursor-pointer
            ${(isSubmited) ? 'custom-file-upload-disabled' : ''}`}>
                    <FontAwesomeIcon icon={faCloudUploadAlt} /><br />
                    {localButtonText}
                </label>}
            {/* <label htmlFor={`file-upload-${index}`} className={`align-centre upload-AR position
            ${(isSubmited) ? 'custom-file-upload-disabled' : ''}`}>
                <FontAwesomeIcon icon={faCloudUploadAlt} /><br />
                {localButtonText}
            </label> */}
            <input id={`file-upload-${index}`} type="file" name={`file-${index}[]`} style={{ display: "none" }}
                onChange={changeHandler} disabled={isSubmited}/>
        </React.Fragment>
    )
}

const mapStateToProps = (state: any) => {
    return {
        isSubmited: getCurrentSyllabusStatus(state),
        selectedSyllabusStatus: state.setContextData.selectedSyllabus.syllabusStatus

    }
}

const mapDispatchToProps = {
    toggleAlertShowStatus
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Uploader)
