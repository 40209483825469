export const Constant = {
    WMS_ALLOWED_FILE_TYPE_SIZE: [
        { name: '.xlsx', size: null }
    ],
    ADDITIONAL_DOCUMENT_ALLOWED_FILE_TYPE_SIZE: [
        { name: '.3g2', size: 26843545600 },
        { name: '.3ga', size: 26843545600 },
        { name: '.aac', size: 26843545600 },
        { name: '.aiff', size: 26843545600 },
        { name: '.amr', size: 26843545600 },
        { name: '.m4a', size: 26843545600 },
        { name: '.m4b', size: 26843545600 },
        { name: '.m4p', size: 26843545600 },
        { name: '.mp3', size: 26843545600 },
        { name: '.wav', size: 26843545600 },

        { name: '.xls', size: 26843545600 },
        { name: '.xlsx', size: 26843545600 },
        { name: '.mdb', size: 26843545600 },
        { name: '.accdb', size: 26843545600 },
        { name: '.xlsb', size: 26843545600 },

        { name: '.odt', size: 26843545600 },
        { name: '.pdf', size: 26843545600 },
        { name: '.rtf', size: 26843545600 },
        { name: '.txt', size: 26843545600 },
        { name: '.doc', size: 26843545600 },
        { name: '.docx', size: 26843545600 },
        { name: '.dotx', size: 26843545600 },
        { name: '.pages', size: 26843545600 },

        { name: '.jpg', size: 15728640 },
        { name: '.png', size: 15728640 },
        { name: '.jpeg', size: 15728640 },
        { name: '.tif', size: 15728640 },
        { name: '.jfif', size: 15728640 },
        { name: '.gif', size: 15728640 },
        { name: '.heic', size: 15728640 },
        { name: '.psd', size: 15728640 },
        { name: '.dox', size: 15728640 },
        { name: '.pcx', size: 15728640 },
        { name: '.bmp', size: 15728640 },
        { name: '.wmf', size: 15728640 },

        { name: '.ppt', size: 26843545600 },
        { name: '.pptx', size: 26843545600 },
        { name: '.gslides', size: 26843545600 },
        { name: '.pptm', size: 26843545600 },
        { name: '.odp', size: 26843545600 },
        { name: '.ink', size: 26843545600 },
        { name: '.potx', size: 26843545600 },
        { name: '.pub', size: 26843545600 },

        { name: '.3gp', size: 26843545600 },
        { name: '.avi', size: 26843545600 },
        { name: '.flv', size: 26843545600 },
        { name: '.m4v', size: 26843545600 },
        { name: '.mkv', size: 26843545600 },
        { name: '.mov', size: 26843545600 },
        { name: '.mp4', size: 26843545600 },
        { name: '.mp4v', size: 26843545600 },
        { name: '.wmp', size: 26843545600 },
        { name: '.wmv', size: 26843545600 },
        { name: '.wlmp', size: 26843545600 },
        { name: '.mts', size: 26843545600 },
        { name: '.mov-1', size: 26843545600 },
        { name: '.mp4-1', size: 26843545600 },
        { name: '.xspf', size: 26843545600 },
        { name: '.mod', size: 26843545600 },
        { name: '.mpg', size: 26843545600 },

        { name: '.html', size: 26843545600 },
        { name: '.htm', size: 26843545600 },
        { name: '.gdoc', size: 26843545600 },
        { name: '.url', size: 26843545600 },
        { name: '.exe', size: 26843545600 },
        { name: '.key', size: 26843545600 }

    ],
    SYLLABUS_STATUS_MESSAGE_FROM_API: {
        FIRST_SAMPLE_REQUESTED: "first sample requested",
        FIRST_SAMPLE_SUBMITTED: "first sample submitted",
        FIRST_SAMPLE_FURTHER_REQUESTED: "further evidence requested for first sample",
        FIRST_SAMPLE_FURTHER_SUBMITTED: "further evidence submitted for first sample",
        SECOND_SAMPLE_REQUESTED: "second sample requested",
        SECOND_SAMPLE_SUBMITTED: "second sample submitted",
        SECOND_SAMPLE_FURTHER_REQUESTED: "further evidence requested for second sample",
        SECOND_SAMPLE_FURTHER_SUBMITTED: "further evidence submitted for second sample",
        SAMPLE_SUBMITTED: "sample already submitted"
    },
    CANDIDATE_GENERIC_DOCUMENT_ALLOWED_FILE_TYPE_SIZE: [
        { name: '.wms', size: null }
    ],
    GET_CALL_TIME_AFTER_UPLOAD: 10000
}