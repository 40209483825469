import _axios from 'axios';
import Store from './../redux-building-blocks/store';
import {
  toggleLoaderShowStatus
} from './../redux-building-blocks/actions';

class PreSignedUrlUploadHelper {
  axios: any;
  constructor() {
    this.axios = _axios;
    this.axios.interceptors.request.use(
      (config: any) => {
        Store.dispatch(toggleLoaderShowStatus(true));
        return config;
      }
    )

    this.axios.interceptors.response.use(
      (response: any) => {
        Store.dispatch(toggleLoaderShowStatus(false));
        return response
      },
      (error: any) => {
        Store.dispatch(toggleLoaderShowStatus(false));
        return Promise.reject(error)
      }
    )
  }

  uploadFile(url: string, fields: any, file: any) {
    const data = {
      ...fields,
      file
    }
    const formData = new FormData();
    for(const key in data) {
      formData.append(key, data[key]);
    }
    return this.axios({
      method: "post",
      url,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
  }



}

const instance = new PreSignedUrlUploadHelper();
Object.freeze(instance);

export default instance;

