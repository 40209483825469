import APIService from '../../services/apiService';
import { downloadSAGFileFailure } from '../actions';
import { call, put } from "redux-saga/effects";

interface IDownload {
    data: {
        url: string
    }
}

export default function* downloadSAGFile(action: any) {
    let res: IDownload;
    try {
        res = yield call([APIService, 'downloadSAGFile'], action.payload);
        window.open(res.data.url, '_blank');
    }
    catch {
        yield put(downloadSAGFileFailure())
    }
}