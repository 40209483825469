import dateFormat from 'dateformat';

class DateConverter{

    static dateConverter(dateTimestamp?:any){
         const convertedDate= dateFormat(dateTimestamp,"dd/mm/yyyy 'UTC' HH:MM");
         console.log(convertedDate);
         return convertedDate;
    }
}

export default DateConverter