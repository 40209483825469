import APIService from '../../services/apiService';
import { fetchEvidenceLevelFilesSuccess, fetchEvidenceLevelFilesFailure} from '../actions';
import { call, put } from "redux-saga/effects";

interface IFolders {
    'file-list': {
        files: any
    }
}

export default function* evidenceLevelFiles(action: any) {
    let res: IFolders| [];
    try {
        res = yield call([APIService, 'fetchEvidenceLevelFiles'], action.payload);
        yield put(fetchEvidenceLevelFilesSuccess((res)))
    }
    catch {
        yield put(fetchEvidenceLevelFilesFailure())
    }
}