import React, { FC, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { connect } from 'react-redux';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { downloadWMSFile } from '../../redux-building-blocks/actions';

import {
    Col,
    Row,
    CardBody,
    Card
} from 'reactstrap';

interface EvidenceProps {
    eachEvidenceItem: any;
    index: number;
}

const CandidateEvidenceDocuments: FC<EvidenceProps> = (props: any): JSX.Element => {
    const [evidenceData, setEvidenceData] = useState({
        evidenceName: '',
        'candidate-evidence-grade': '',
        evidenceGradeDetails: []
    });
    const [fileType, setFileType] = useState('evidence');

    // useEffect(() => {
    //     if (!props.location.pathname.includes("rationale-document") && !props.location.pathname.includes("additional-document") && !props.location.pathname.includes("candidate-evidence")) {
    //         setFileType('wms');
    //     }
    // }, [props.location]);


    useEffect(() => {
        if (!_.isEqual(props.eachEvidenceItem, evidenceData)) {
            setEvidenceData(props.eachEvidenceItem);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.eachEvidenceItem]);

    const downloadCandidateEvidence = (key:any) => {
        //console.log('location value => ', props,key);
        //console.log(props.eachEvidenceItem);
        console.log(props.eachEvidenceItem["candidate-evidence-file-location"]);
        if (props.eachEvidenceItem.evidenceName !== 'NONE' && props.eachEvidenceItem["candidate-evidence-file-location"]) {
            props.downloadWMSFile(props.eachEvidenceItem["candidate-evidence-file-location"], props.eachEvidenceItem.uploadedProps.uploadedFileList[key]['file-name'], 'evidence')
        } else {
            props.toggleAlertShowStatus({
                isShowAlert: true,
                alertColor: 'info',
                alertMessage: `No Evidence file present.`
            })
        }
    }


    return (
        <React.Fragment>
            <Card key={props.index}>
                <CardBody className="card-body pxy-2">
                    <Row className="align-items-center">
                        <Col md={8}>
                            <div className="pb-4"> <FontAwesomeIcon className="fileUpload" icon={faFolder} />
                                <span className="pl-2 evidenceLabel text-capitalize">{evidenceData['evidenceName']}:</span>
                                <span className="pl-2 evidenceLabel"> {evidenceData['candidate-evidence-grade'] ? 'Grade ' + evidenceData['candidate-evidence-grade'] : 'No grades present'}</span>
                            </div>
                        </Col>
                    </Row>
                    {evidenceData && (evidenceData as any)?.uploadedProps?.uploadedFileList?.map((eachFile: any, key: any) => {
                        return (
                            <Row className="align-items-center" key={key}>
                                <Col md={8}>
                                    <div className="pl-2 evidenceLabel">{eachFile['file-name']}</div>
                                </Col>
                                <Col md={4}>
                                    <button className="btn downloadBtn ml-4" onClick={() => downloadCandidateEvidence(key)}>
                                        <FontAwesomeIcon icon={faDownload} />
                                        <span className="font-weight-bold ml-2">Download</span>
                                    </button>
                                </Col>
                            </Row>
                        )
                    })}
                    {!evidenceData || !(evidenceData as any)?.uploadedProps?.uploadedFileList.length && <div className="font-weight-500 grayText font-size18">No evidence files present</div>}
                </CardBody>
            </Card>
        </React.Fragment>)
}

const mapStateToProps = (state: any) => {
    return {
        rationalDocuments: state.setContextData?.candidateGenericRationaleDocuments,
        activeTab: state.setContextData.activeTab,
        location: state.setContextData.location
    }
}

const mapDispatchToProps = {
    downloadWMSFile
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateEvidenceDocuments);