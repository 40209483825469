import { takeLatest, takeEvery } from 'redux-saga/effects';
import getContextData from './fetchContextData';
import getCandidateDocuments from './fetchCandidateDocs';
import downloadSAGFile from './downloadSAGFile';
import downloadWMSFile from './downloadWMSFile';
import candidateFoldersLocation from './candidateFoldersLocation';
import evidenceLevelFiles from './evidenceLevelFiles';
import updateCandidateGrades from './updateCandidateGrade';
import evidenceSubmission from './evidenceSubmission';
import getCentresData from './fetchCentresData';
import fetchWMSStatus from './fetchWMSStatus';

import {
    GET_CENTRES_DATA,
    GET_CONTEXT_DATA,
    FETCH_CANDIDATE_DOCUMENTS,
    FETCH_WMS_STATUS,
    DOWNLOAD_SAG_FILE,
    DOWNLOAD_WMS_FILE,
    FETCH_CANDIDATE_FOLDERS,
    FETCH_EVIDENCE_FILES,
    UPDATE_CANDIDATE_GRADE,
    EVIDENCE_SUBMISSION
} from '../action-types';


export default function* sagaWatcher() {
    yield takeLatest(GET_CENTRES_DATA, getCentresData)
    yield takeLatest(GET_CONTEXT_DATA, getContextData);
    yield takeLatest(FETCH_CANDIDATE_DOCUMENTS, getCandidateDocuments);
    yield takeEvery(FETCH_WMS_STATUS, fetchWMSStatus);
    yield takeLatest(DOWNLOAD_SAG_FILE, downloadSAGFile);
    yield takeLatest(DOWNLOAD_WMS_FILE, downloadWMSFile);
    yield takeLatest(FETCH_CANDIDATE_FOLDERS, candidateFoldersLocation);
    yield takeEvery(FETCH_EVIDENCE_FILES, evidenceLevelFiles);
    yield takeLatest(UPDATE_CANDIDATE_GRADE, updateCandidateGrades);
    yield takeLatest(EVIDENCE_SUBMISSION, evidenceSubmission)
}