import _axios from 'axios';
import jwtHelper from '../helper/jwt-helper';
import awsTokenHelper from '../helper/aws-token-helper';
import Store from './../redux-building-blocks/store';
import {
  toggleLoaderShowStatus,
  toggleAlertShowStatus
} from './../redux-building-blocks/actions';


class APIService {
  axios: any;
  api: any;
  constructor() {
    this.axios = _axios;
    this.api = this.axios.create({
      baseURL: process.env.REACT_APP_AWS_API_BASE_URL
    })

    this.api.interceptors.request.use(
      async (config: any) => {
        try {
          Store.dispatch(toggleLoaderShowStatus(true));
          config.headers['Authorization'] = jwtHelper.getJwtToken();
          // config.headers['Id'] = jwtHelper.getEncodedIdStringFromJWT();
          return config
        } catch (error) {
          Store.dispatch(toggleLoaderShowStatus(false));
          console.log('Error in interceptor while fetching AWS access token', error);
          this.handleAccessError('AWS access token get failed');
          Store.dispatch(toggleAlertShowStatus({
            isShowAlert: true,
            alertColor: 'danger',
            alertMessage: `Something went wrong. Please login again`
          }))
        }
      }
    )

    this.api.interceptors.response.use(
      (response: any) => {
        Store.dispatch(toggleLoaderShowStatus(false));
        return response
      },
      (error: any) => {
        Store.dispatch(toggleLoaderShowStatus(false));
        console.log('error on response interceptor', error.response);
        //Handle access denied error here when jwt expires
        if (error.response.status === 500) {
          this.handleAccessError(error.response.data.message);
        }
        else if(error?.response?.status === 401 || error?.response?.status === 403) {
          Store.dispatch(toggleAlertShowStatus({
            isShowAlert: true,
            alertColor: 'danger',
            alertMessage: `Token Expired, Please login again`
          }));
          setTimeout(() => {
            this.handleAccessErrorOrLogout(true);
          }, 500);
        }

        else if (error.response.data.message || error.response.data.message !== '') {
          Store.dispatch(toggleAlertShowStatus({
            isShowAlert: true,
            alertColor: 'danger',
            alertMessage: `${error.response.data.message}`
          }))
        }

        return Promise.reject(error)
      }
    )
  }

  

  handleAccessError(msg: string) {
    // jwtHelper.jwtTokenResetInStorage();
    // window.location.replace(process.env.REACT_APP_REDIRECT_URL_ON_SESSION_EXPIRE as string);
  }

  fetchCentresData() {
    return this.api.get(`/v1/testcenters`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  fetchContextDataTestCenter(testCenterId: string) {
    return this.api.get(`/v1/testcenter?testcenter=${testCenterId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  fetchContextData(id: string) {
    return this.api.get(`/v1/testcenter?testcenter=${id}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }



  fetchCandidateDocuments(centreId: string, syllabusId: string) {
    return this.api.get(`/v1/testcenter/syllabus/documents?testcenter=${centreId}&syllabus=${syllabusId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  fetchAdditionalDocuments(centreId: string, syllabusId: string) {
    return this.api.get(`/v1/testcenter/syllabus/additional-materials?testcenter=${centreId}&syllabus=${syllabusId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }


  downloadSAGFile(payload: any) {
    return this.api.post('/v1/testcenter/syllabus/download', payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  getPreSignedUrlForWmsFileUpload(payload: any) {
    return this.api.post(`v1/testcenter/syllabus/wms`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response: any) => response.data)
  }

  downloadWMSFile(payload: any) {
    return this.api.post('v1/download', payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }


  getPreSignedUrlForRationalDocUpload(syllabusId: string, payload: any) {
    return this.api.post(`v1/testcenter/syllabus/documents?syllabus=${syllabusId}`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response: any) => response.data)
  }

  deleteRationalDoc(syllabusId: string, fileName: string, upload = 1) {
    return this.api.delete(`/v1/testcenter/syllabus/documents?syllabus=${syllabusId}&filename=${fileName}&upload=${upload}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response: any) => response.data)
  }

  getPreSignedUrlForAdditionalDocUpload(syllabusId: string, payload: any) {
    return this.api.post(`v1/testcenter/syllabus/additional-materials/document?syllabus=${syllabusId}`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response: any) => response.data)
  }

  deleteAdditionalDoc(syllabusId: string, fileName: string, upload = 1) {
    return this.api.delete(`/v1/testcenter/syllabus/additional-materials/document?syllabus=${syllabusId}&filename=${fileName}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response: any) => response.data)
  }

  fetchCandidateFolders(data: any) {
    console.log('In here:', data)
    return this.api.get(`v1/testcenter/syllabus/candidate-folders?testcenter=${data.centreNumber}&candidate=${data.candidateId}&syllabus=${data.syllabusId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response: any) => response.data)
  }

  fetchEvidenceLevelFiles(data: any) {
    return this.api.get(`v1/testcenter/syllabus/candidate-files?file-location=${data.location}&syllabus=${data.syllabusId}&testcenter=${data.centreId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response: any) => response.data);
  }

  getPreSignedURLForCandidateEvidence(payload: any) {
    return this.api.post(`v1/testcenter/syllabus/candidate-files/document`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response: any) => response.data)
  }
  updateCandidateGrade(payload: any) {
    return this.api.post(`v1/testcenter/syllabus/candidate-folders/grades`, payload, {
      headers: {
        'Content-Type': 'text/plain'
      }
    }).then((response: any) => response.data);
  }

  deleteCandidateEvidenceDoc(filelocation: string, fileName: string) {
    return this.api.delete(`/v1/testcenter/syllabus/candidate-files/document?filepath=${filelocation}/${fileName}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response: any) => response.data)
  }

  fetchWMSStatus(centreId: string, syllabusId: string) {
    return this.api.get(`/v1/testcenter/syllabus/wms-status?testcenter=${centreId}&syllabus=${syllabusId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response: any) => response.data)
  }

  submitEvidence(payload: any) {
    return this.api.patch(`/v1/testcenter/syllabus/wms`, payload, {
      headers: {
        'Content-Type': 'text/plain'
      }
    }).then((response: any) => response.data);
  }

  handleAccessErrorOrLogout(isActiveRedirect: boolean = false) {
    jwtHelper.jwtTokenResetInStorage();
    if (isActiveRedirect) {
      window.location.replace(process.env.REACT_APP_REDIRECT_URL_ON_SESSION_EXPIRE as string);
    }
  }

}



const instance = new APIService();
Object.freeze(instance);

export default instance;