import APIService from '../../services/apiService';
import { fetchCandidateFolderLocationsFailure, fetchCandidateFolderLocationsSuccess, fetchEvidenceLevelFilesFailure } from '../actions';
import { call, put, select } from "redux-saga/effects";
import { Constant } from '../../config/constant';
import Store from './../../redux-building-blocks/store';

interface IFolders {
    data: {
        data: any
    }
}

interface ICentreIdAndSelectedSyllabus {
    currentCentreId: any,
    selectedSyllabus: any
}

const getCentreIdAndSyllabus = (state: any): any => ({
    currentCentreId: state.setContextData?.selectedCentre,
    selectedSyllabus: state.setContextData?.selectedSyllabus
})

const createEmptyEvidence = async (_currentCentreId: any, selectedSyllabus: any, candidateIndex: any, res: any) => {
    const selectedCandidate = selectedSyllabus.candidateDetails[candidateIndex];
    selectedCandidate['evidenceList'] = [];
    for (let i = 0; i < res.length; i++) {
        const length = res[i]['candidate-evidence']['candidate-evidence-file-location'].split('/').length;
        const evidenceName = res[i]['candidate-evidence']['candidate-evidence-file-location'].split('/')[length - 1];
        const emptyEvidenceObj = {
            evidenceName: evidenceName.split('-').join(' '),
            // evidenceGradeDetails: (selectedSyllabus as any).candidateDetails[candidateIndex].gradeSet,
            'candidate-evidence-file-location': res[i]['candidate-evidence']['candidate-evidence-file-location'],
            'candidate-evidence-grade': res[i]['candidate-evidence']['candidate-evidence-grade'],
            'candidate-evidence-id': res[i]['candidate-evidence']['candidate-evidence-id'],
            uploadedProps: await getUploadedProps(res[i]['candidate-evidence']['candidate-evidence-file-location'], selectedSyllabus.syllabusNumber, _currentCentreId)
        }
        selectedCandidate.evidenceList.push(emptyEvidenceObj)
    }
    return selectedCandidate
}

const getUploadedProps = (location: string, syllabusId: string, centreId: any) => {
    return new Promise((resolve, reject) => {
        APIService.fetchEvidenceLevelFiles(({ location, syllabusId, centreId })).then((payloadData: any) => {
            const responseData = payloadData[0]['file-list'].files
            if (responseData !== null) {
                // let uploadedFiles: string[] = [];
                // uploadedFiles = responseData.map((each: { [x: string]: any; }) => each['file-name']);
                resolve({
                    // uploadedFiles: [...uploadedFiles],
                    uploadedFileList: responseData,
                    // 'maxCount': 10,
                    // 'fileFormatAndSizeSupported': Constant.ADDITIONAL_DOCUMENT_ALLOWED_FILE_TYPE_SIZE,
                })
            } else {
                resolve({
                    // uploadedFiles: [],
                    uploadedFileList: [],
                    // 'maxCount': 10,
                    // 'fileFormatAndSizeSupported': Constant.ADDITIONAL_DOCUMENT_ALLOWED_FILE_TYPE_SIZE
                });
            }
        }).catch((error: any) => {
            Store.dispatch(fetchEvidenceLevelFilesFailure())
            reject(error);
        });
    });
}
export default function* candidateFoldersLocation(action: any) {
    let res: IFolders;
    try {
        res = yield call([APIService, 'fetchCandidateFolders'], action.payload);
        const state: ICentreIdAndSelectedSyllabus = yield select(getCentreIdAndSyllabus);

        const index = (state.selectedSyllabus as any).candidateDetails.findIndex((each: any) => each.candidateNumber === action.payload['candidateId']);

        (async (Store) => {
            if (index >= 0) {
                const basicStructForEvidence: any = await createEmptyEvidence(state.currentCentreId, state.selectedSyllabus, index, res);
                Store.dispatch(fetchCandidateFolderLocationsSuccess(res, action.payload['candidateId'], basicStructForEvidence))
            }
        })(Store);




    }
    catch {
        yield put(fetchCandidateFolderLocationsFailure())
    }
}