import APIService from '../../services/apiService';
import { setSyllabusList, fetchContextDataError } from '../actions';
import { call, put } from "redux-saga/effects";

interface IContextData {
    data: {
        data: []
    }
}


export default function* getContextData(action: any) {
    let res: IContextData;
    try {
        res = yield call([APIService, 'fetchContextData'],action.payload);
        //const syllabusStatusres: Array<any> = yield call([APIService, 'fetchSyllabusStatus'],action.payload);
        console.log('response in saga:', res.data.data);
        yield put(setSyllabusList(res.data.data));
       //console.log('status response', syllabusStatusres);
        //yield put(setSyllabusList(res.data.data));
    }
    catch {
        console.log('context error::');
        yield put(fetchContextDataError())
    }
}